.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Adjust the .App-header or add .App-main for main content styling if needed */
.App-main {
  /* Styling for the main content area */
  background-color: #282c34;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.underline-on-hover:hover {
  text-decoration: underline;
}

/* Additional styles for LoginPage or other components as necessary */
.LoginPage {
  /* Specific styles for your login page if needed */
}

/* Ensure main content is properly aligned and styled */
main {
  color: white;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Adjust based on header size to fill the screen */
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: #000;
}
